import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/SEO/SEO"
import Slider from "../sections/home/Slider/Slider"

import Content from "../sections/common/Content/Content"
import FancyNav from "../sections/common/FancyNav/FancyNav"
import Location from "../sections/common/Location/Location"
import Reviews from "../sections/common/Reviews/Reviews"

const IndexPage = ({ data }) => {
  if (!data) return null

  const homePage = data.prismicHome.data
  const sliderItems = homePage.slider_items

  return (
    <>
      <SEO
        title={homePage.meta_title.text}
        description={homePage.meta_description.text}
      />
      <section>
        <Slider sliderItems={sliderItems} />
      </section>
      <section>
        <Content />
      </section>
      <section className="py-4 md:py-6">
        <FancyNav />
      </section>
      <section>
        <Location />
      </section>
      <section className="pb-5 md:pb-7 lg:pb-10">
        <Reviews />
      </section>
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    prismicHome {
      data {
        slider_items {
          big_title {
            text
          }
          sub_title {
            text
          }
          image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
      }
    }
  }
`
